import React from "react";
import { FaTwitter } from "react-icons/fa";
import { BiLogoDiscordAlt } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { VscGithubInverted } from "react-icons/vsc";

const Navbar = () => {
  return (
    <header className="w-full">
      <div className="w-full h-[90px] flex justify-between items-center m-auto md:px-4 px-3">
        {/* logo ---->  */}
        <a
          href="https://www.sunscreen.tech/"
          className="text-[30px] sm:text-[35px] md:text-[40px] font-logo logo-color font-semibold "
        >
          SUNSCREEN
        </a>
        {/* social links ------> */}
        {/*<div className="flex justify-center items-center gap-1 sm:gap-2 md:gap-3">
          {socialLinks.map((item, index) => {
            return (
              <div className="w-[30px] sm:w-[35px] md:w-[40px] h-[30px] sm:h-[35px] md:h-[40px] button-colors hover:opacity-80 rounded-full flex justify-center items-center hover:scale-110 transform duration-200 ease-in-out">
                <a href={item.path} key={index}>
                  {item.icon}
                </a>
              </div>
            );
          })}
        </div>*/}
      </div>
    </header>
  );
};

const socialLinks = [
  {
    path: "https://twitter.com/SunscreenTech",
    icon: <FaTwitter className="text-[20px] sm:text-[23px] md:text-[26px]" />,
  },
  {
    path: "https://discord.gg/sunscreen",
    icon: (
      <BiLogoDiscordAlt className="text-[20px] sm:text-[23px] md:text-[26px]" />
    ),
  },
  {
    path: "mailto:hello@sunscreen.tech",
    icon: <MdEmail className="text-[20px] sm:text-[23px] md:text-[26px]" />,
  },
  {
    path: "https://github.com/Sunscreen-tech",
    icon: (
      <VscGithubInverted className="text-[20px] sm:text-[23px] md:text-[26px]" />
    ),
  },
];

export default Navbar;
