import React, { useEffect, useState } from "react";
import { generateRandomString } from "./Shared/utils";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import PendingOrders from "./PendingOrders";
import OrderForm from "./OrderForm";
import { useCookies } from "react-cookie";
import OrderBook from "./OrderBook";
import { ClearanceType } from "./Shared/BackendObjects";
import { SyncedObjects } from "./Shared/SyncedObjects";

type DarkPoolDemoProps = {
}

const DarkPoolDemo = (props: React.PropsWithChildren<DarkPoolDemoProps>) => {
  const CDA = "cda";
  const BINANCE = "binance";
  const COINBASE = "coinbase";

  const [cookies, setCookie] = useCookies(["user"]);
  const [expType, setExpType] = useState(CDA);
  const [cachedUser, setCachedUser] = useState('');
  const [priceQuote, setPriceQuote] = useState(0);
  const [exchange, setExchange] = useState(BINANCE);

  useEffect(() => {
    if (!cookies.user) {
      const user = generateRandomString();
      setCookie("user", user, { path: "/" });
      setCachedUser(user);
      SyncedObjects.setUser(user);
    } else {
      setCachedUser(cookies.user);
      SyncedObjects.setUser(cookies.user);
    }

    const timerId = SyncedObjects.startLoop();

    SyncedObjects.getBinanceStatistics((currPrice: string) => {
      try {
        const parsed = parseFloat(currPrice);
        setPriceQuote(parsed);
      } catch { }
    });

    return () => {
      clearInterval(timerId);
    }
  }, []);

  return (
    <React.Fragment>
      <div
        id="formButtonSection"
        className="w-full min-h-[calc(100vh-100px)] flex justify-center items-center py-0"
      >
        <div className="w-full h-full m-auto md:px-4 sm:px-3 px-2 flex justify-center items-center">
          <div className="w-full grid grid-cols-12 gap-0 h-full">
            <div className="col-span-12 grid grid-cols-15 gap-1 border-color-code border-solid border-b-2 pb-2">
              <div className="col-span-2 align-middle">
                {/*<div className="relative inline-block crypto-icon">
                  <img src="https://dynamic-assets.coinbase.com/dbb4b4983bde81309ddab83eb598358eb44375b930b94687ebe38bc22e52c3b2125258ffb8477a5ef22e33d6bd72e32a506c391caa13af64c00e46613c3e5806/asset_icons/4113b082d21cc5fab17fc8f2d19fb996165bcce635e6900f7fc2d57c4ef33ae9.png"></img>
                </div>
                <div className="relative inline-block crypto-icon">
                  <img src="https://dynamic-assets.coinbase.com/e785e0181f1a23a30d9476038d9be91e9f6c63959b538eabbc51a1abc8898940383291eede695c3b8dfaa1829a9b57f5a2d0a16b0523580346c6b8fab67af14b/asset_icons/b57ac673f06a4b0338a596817eb0a50ce16e2059f327dc117744449a47915cb2.png" className="absolute -left-2"></img>
  </div>*/}
                <div className="relative inline-block">
                  <div className="pl-2 inline-block font-bold text-[25px] font-regularText primary-color">ETH-BTC</div>
                </div>
              </div>
              <div className="col-span-2 grid justify-items-start">
                <div className="alt-primary-color text-[11px] font-bold">DATA SOURCE</div>
                <select name="timeinforce" id="timeinforce" className="primary-color text-[11px] font-bold order-form-select standard-background pt-0 pb-0 pr-1 pl-2" onChange={(e) => setExchange(e.target.value)} value={exchange}>
                  <option value={BINANCE}>BINANCE</option>
                  <option value={COINBASE}>COINBASE</option>
                </select>
              </div>
              <div className="col-span-2">
                <div className="font-semiBold text-[13px] alt-primary-color">LAST PRICE (BTC)</div>
                <div className="font-bold text-[18px] primary-color">{priceQuote}</div>
              </div>
              <div className="col-span-5" />
              <div className="col-span-4 grid justify-items-end">
                <div className="alt-primary-color text-[11px] font-bold">PICK YOUR EXPERIENCE</div>
                <select name="timeinforce" id="timeinforce" className="primary-color text-[11px] font-bold order-form-select standard-background pt-0 pb-0 pr-1 pl-2" onChange={(e) => { setExpType(e.target.value); SyncedObjects.setClearance(e.target.value == CDA ? ClearanceType.CDA : ClearanceType.VM); }} value={expType}>
                  <option value={CDA}>CONTINUOUS DOUBLE AUCTION (CDA)</option>
                  <option value="vm">VOLUME MATCHING</option>
                </select>
              </div>
            </div>
            <div className="col-span-8 h-full border-solid border-r-2 border-l-2 border-color-code pt-0">
              <div className="h-[55vh] border-solid border-b-2 border-color-code">
                {
                  exchange === BINANCE &&
                  <AdvancedRealTimeChart theme="dark" autosize symbol='BINANCE:ETHBTC' allow_symbol_change={false} save_image={false} hide_side_toolbar></AdvancedRealTimeChart>
                }
                {
                  exchange === COINBASE &&
                  <AdvancedRealTimeChart theme="dark" autosize symbol='COINBASE:ETHBTC' allow_symbol_change={false} save_image={false} hide_side_toolbar></AdvancedRealTimeChart>
                }
              </div>
              <div className="h-[30vh]">
                <PendingOrders user={cachedUser} isCda={expType === CDA} />
              </div>
            </div>
            <div className="col-span-2 h-full">
              <OrderBook />
            </div>
            <div className="col-span-2 h-full">
              <OrderForm user={cachedUser} isCda={expType === CDA} currentMidPointPrice={priceQuote} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DarkPoolDemo;
