import { useEffect, useState } from "react";
import { SyncedObjects } from "./Shared/SyncedObjects";
import { Tooltip } from "flowbite-react";
import { FaInfoCircle } from "react-icons/fa";
import { BackendCommunicator } from "./Shared/BackendCommunicator";

type OrderBookProps = {
}

const OrderBook = (props: React.PropsWithChildren<OrderBookProps>) => {
  const [numBuyOrders, setNumBuyOrders] = useState(0);
  const [numSellOrders, setNumSellOrders] = useState(0);
  const [encryptedBuyOrders, setEncryptedBuyOrders] = useState<string[]>([]);
  const [encryptedSellOrders, setEncryptedSellOrders] = useState<string[]>([]);
  const [lastUpdate, setLastUpdate] = useState(0);

  useEffect(() => {
    if (numBuyOrders > encryptedBuyOrders.length || numSellOrders > encryptedSellOrders.length || (lastUpdate + 5000) < Date.now()) {
      updateData();
    }
  }, [numBuyOrders, numSellOrders])


  useEffect(() => {
    const callbackId = SyncedObjects.getOutstandingOrders((sells: number, buys: number) => {
      setNumBuyOrders(buys);
      setNumSellOrders(sells);

    });

    return () => {
      SyncedObjects.clearOutstandingOrderCallback(callbackId);
    }
  }, []);

  const updateData = async () => {
    try {
      let attempts = 5;
      while (attempts > 0 && (numBuyOrders > encryptedBuyOrders.length || numSellOrders > encryptedSellOrders.length)) {
        try {
          const [buys, sells] = await BackendCommunicator.getOutstandingOrderDetails(SyncedObjects.getClearance());
          setEncryptedBuyOrders(buys);
          setEncryptedSellOrders(sells);
          break;
        } catch (e) {
          console.log("Failed to get outstanding order details " + e);
        }

        attempts--;
      }
      setLastUpdate(Date.now());
    } catch (e) {
      console.log("Failed to update outstanding order details " + e);
      setLastUpdate(Date.now() - 2000);
    } finally {

    }
  }

  const getStringForId = (id: number, isSell: boolean): string => {
    if (isSell) {
      if (id < encryptedSellOrders.length) {
        return encryptedSellOrders[id];
      } else {
        return "Awaiting...";
      }
    } else {
      if (id < encryptedBuyOrders.length) {
        return encryptedBuyOrders[id];
      } else {
        return "Awaiting...";
      }
    }
  };

  const formatCipher = (cipher: string, offset: number) => {
    if (cipher.length > (58 + offset)) {
      return "..." + cipher.substring(50 + offset, 58 + offset) + "...";
    } else {
      return cipher;
    }
  }

  return (
    <div className="border-r-2 border-solid border-color-code h-full">
      <div className='pt-2 pb-2 pl-2 pr-2 border-b-2 border-color-code border-solid flex flex-row'>
        <span className="mr-2 font-bold primary-color text-[15px]">Encrypted Order Book</span>
        <Tooltip style="light" content="The orders listed below are encrypted. Neither you nor the dark pool operator is able to see the clear text of the orders until a match occurs. You can decrypt your own orders in the 'Orders' pane.">
          <FaInfoCircle className="text-[15px] primary-color" />,
        </Tooltip>
      </div>
      <div className="grid grid-cols-2 pt-2 pb-2 pl-2 pr-2 text-[11px] gap-0 border-b-2 border-color-code border-solid justify-items-end">
        <div className='col-span-1 alt-primary-color'>AMOUNT (ETH)</div>
        <div className='col-span-1 alt-primary-color'>PRICE (BTC)</div>
      </div>
      <div className="h-[30vh]">
        <div className="grid grid-cols-2 pt-2 pb-2 pl-2 pr-2 text-[11px] gap-2 justify-items-end">
          {Array.from({ length: numSellOrders }, (x, i) => i).map((x) => {
            return (
              <>
                <div className='col-span-1 primary-color'>{formatCipher(getStringForId(x, true), 0)}</div>
                <div className='col-span-1 sell-color'>{formatCipher(getStringForId(x, true), 20)}</div>
              </>
            )
          })}
        </div>
      </div>
      <div className="grid grid-cols-2 pt-2 pb-2 pl-2 pr-2 text-[11px] gap-0 border-t-2 border-b-2 border-color-code border-solid justify-items-end">
        <div className='col-span-1 alt-primary-color'>AMOUNT (ETH)</div>
        <div className='col-span-1 alt-primary-color'>PRICE (BTC)</div>
      </div>
      <div className="h-[30vh]">
        <div className="grid grid-cols-2 pt-2 pb-2 pl-2 pr-2 text-[11px] gap-2 justify-items-end">
          {Array.from({ length: numBuyOrders }, (x, i) => i).map((x) => {
            return (
              <>
                <div className='col-span-1 primary-color'>{formatCipher(getStringForId(x, false), 0)}</div>
                <div className='col-span-1 buy-color'>{formatCipher(getStringForId(x, false), 20)}</div>
              </>
            )
          })}
        </div>
      </div>

    </div>
  );
};

export default OrderBook;
