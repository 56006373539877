import './App.css';
import Header from './components/header';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { DarkPoolHome } from './DarkPoolHome';
import { detectMobile } from './components/Shared/utils';


const Home = () => {
  return (
    <div className="w-full">
      <Header />
      <div className="w-full flex">
        <div className="w-full max-w-[1280px] m-auto sm:gap-4 gap-1 md:px-4 px-3 h-[calc(100vh-90px)] flex flex-col justify-center sm:mt-0 -mt-[90px] items-center">
          {/* title -------->  */}
          <p className="text-[27px] leading-[43px] sm:text-[40px] md:text-[60px] text-center sm:leading-14 md:leading-16 uppercase primary-color font-regularText tracking-wider">
            PRIVATE TRADING WITH DARK POOLS
          </p>
          {/* Powered by ------> */}
          <p className="text-[15px] sm:text-[20px] md:text-[30px] font-regularText font-medium primary-color">
            Powered by FHE. Learn more <a href="https://blog.sunscreen.tech/" target="_blank" rel="noreferrer">here.</a>
          </p>
          {/* try it now button ------> */}
          <Link to="/darkpool"
            className="text-center mt-5 cursor-pointer text-[8px] sm:text-[15px] md:text-[24px] font-regularText font-semibold tracking-normal hover:opacity-80 button-colors px-8 py-[6px] sm:py-[8px] rounded-md"
          >
            Try out the demo
          </Link>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/darkpool" Component={DarkPoolHome} />
      </Routes>
    </Router>
  );
}


export default App;
