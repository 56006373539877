import axios from "axios";
import { ClearanceType } from "./BackendObjects";
import { BackendCommunicator } from "./BackendCommunicator";

export class SyncedObjects {
  private static Clearance = ClearanceType.CDA;
  private static User = '';
  private static OutstandingOrderCallbacks: ((sells: number, buys: number) => void)[] = [];
  private static BinanceCallbacks: ((curr_price: string) => void)[] = [];
  private static PendingOrderCallbacks: ((orders: number[]) => void)[] = [];
  private static CurrentIteration = 0;
  private static ForceUpdateCount = 5;
  private static IsOperationInProgress = false;


  public static setClearance(clearance: ClearanceType) {
    SyncedObjects.Clearance = clearance;
  }

  public static getClearance(): ClearanceType {
    return SyncedObjects.Clearance;
  }

  public static setUser(user: string) {
    SyncedObjects.User = user;
  }

  public static getUser(): string {
    return SyncedObjects.User;
  }

  public static forceUpdate() {
    SyncedObjects.ForceUpdateCount = 5;
  }

  public static startLoop(): NodeJS.Timer {
    return setInterval(async () => {
      if (!SyncedObjects.IsOperationInProgress) {
        SyncedObjects.IsOperationInProgress = true;
        if (SyncedObjects.OutstandingOrderCallbacks != null && SyncedObjects.OutstandingOrderCallbacks.length > 0) {
          if (SyncedObjects.CurrentIteration % 20 == 0 || SyncedObjects.ForceUpdateCount > 0) {
            try {
              const outstandingOrders = await BackendCommunicator.getOutstandingOrders(SyncedObjects.Clearance);
              for (const callback of SyncedObjects.OutstandingOrderCallbacks) {
                callback(outstandingOrders[1], outstandingOrders[0]);
              }
            } catch {
              console.log("Error getting statistics");
            }
          }
        }

        if (SyncedObjects.BinanceCallbacks != null && SyncedObjects.BinanceCallbacks.length > 0) {
          if (SyncedObjects.CurrentIteration % 50 == 0) {
            try {
              const paramsResponse = await axios.get(
                'https://api.coincap.io/v2/markets?exchangeId=binance&baseSymbol=eth&quoteSymbol=btc',
                {
                  headers: {
                    'Content-Type': 'text/plain',
                  }
                }
              );
              for (const callback of SyncedObjects.BinanceCallbacks) {
                callback(parseFloat(paramsResponse["data"]["data"][0]["priceQuote"]).toFixed(3));
              }
            } catch {
              console.log("Error getting statistics");
            }
          }
        }

        if (SyncedObjects.PendingOrderCallbacks != null && SyncedObjects.PendingOrderCallbacks.length > 0) {
          if (SyncedObjects.CurrentIteration % 50 == 0 || SyncedObjects.ForceUpdateCount > 0) {
            console.log("Getting pending orders");
            try {
              const pendingOrders = await BackendCommunicator.getOrdersForUserId(SyncedObjects.User, SyncedObjects.Clearance);

              for (const callback of SyncedObjects.PendingOrderCallbacks) {
                if (pendingOrders != null && pendingOrders.length > 0) {
                  callback(pendingOrders);
                } else {
                  console.log("No pending orders");
                  callback([]);
                }
              }
            }
            catch (e) {
              console.log("Error getting pending orders. " + e)
            }
          }
        }
        SyncedObjects.ForceUpdateCount = (SyncedObjects.ForceUpdateCount > 0) ? SyncedObjects.ForceUpdateCount - 1 : 0;
        SyncedObjects.IsOperationInProgress = false;
        SyncedObjects.CurrentIteration = (SyncedObjects.CurrentIteration + 1) % 100;
      }
    }, 100)
  }

  public static getOutstandingOrders(callback: (sells: number, buys: number) => void): number {
    return SyncedObjects.OutstandingOrderCallbacks.push(callback);
  }

  public static getBinanceStatistics(callback: (curr_price: string) => void): number {
    return SyncedObjects.BinanceCallbacks.push(callback);
  }

  public static getPendingOrders(callback: (orders: number[]) => void): number {
    return SyncedObjects.PendingOrderCallbacks.push(callback);
  }

  public static clearOutstandingOrderCallback(id: number) { }
  public static clearPendingCallback(id: number) { }
}