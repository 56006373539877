export enum OrderType {
  GoodTillCanceled = 0,
  FillOrKill = 1,
}

export enum OrderStatus {
  Provisioning = 0,
  Processing = 1,
  Canceled = 2,
  Completed = 3,
}

export enum ClearanceType {
  CDA = 0,
  VM = 1,
}

export type Order = {
  user_id: string;
  order_id: number;
  order_qty: string;
  pending_qty: string;
  order_price: string;
  is_sell: boolean;
  order_status: OrderStatus;
  order_type: OrderType;
  matches: number[];
};

export type DecryptedValue = {
  is_decrypted: boolean;
  encrypted_value: string;
  decrypted_value: number;
};

export type DecryptedOrder = {
  user_id: string;
  order_id: number;
  order_qty: DecryptedValue;
  pending_qty: DecryptedValue;
  order_price: DecryptedValue;
  is_sell: boolean;
  order_status: OrderStatus;
  order_type: OrderType;
  matches: number[];
};

export type Match = {
  match_id: number;
  your_order_id: number;
  qty: number;
  price: number;
}

export function convertOrderTypeToString(o: OrderType): string {
  switch (o) {
    case OrderType.GoodTillCanceled:
      return "GTC";
    case OrderType.FillOrKill:
      return "Fill/Kill";
    default:
      return "Unknown Order Type " + o;
  }
}

export function convertOrderStatusToString(o: OrderStatus): string {
  switch (o) {
    case OrderStatus.Provisioning:
      return "Submitting";
    case OrderStatus.Processing:
      return "Open";
    case OrderStatus.Canceled:
      return "Canceled";
    case OrderStatus.Completed:
      return "Filled";
    default:
      return "Unknown Order Status";
  }
}

export const MIN_QTY = 1;
export const MAX_QTY = (1 << 16 - 1);

export const MIN_PRICE = 1;
export const MAX_PRICE = (1 << 16 - 1);

export const QTY_FACTOR = 100.0;
export const PRICE_FACTOR = 10000.0;

export const displayPrice = (toParse: number): string => {
  const value = (toParse * 1.0) / PRICE_FACTOR;
  return value.toFixed(4);
}

export const displayPriceFromDecryptedValue = (toParse: DecryptedValue): string => {
  if (toParse.is_decrypted) {
    const value = (toParse.decrypted_value * 1.0) / PRICE_FACTOR;
    return value.toFixed(4) + " BTC";
  } else {
    return "Decrypting"
  }
}

export const displayAmount = (toParse: number): string => {
  const value = (toParse * 1.0) / PRICE_FACTOR / QTY_FACTOR;
  return value.toFixed(4);
}

export const displayAmountFromDecryptedValue = (toParse1: DecryptedValue, toParse2: DecryptedValue): string => {
  if (toParse1.is_decrypted && toParse2.is_decrypted) {
    const value = (toParse1.decrypted_value * toParse2.decrypted_value * 1.0) / PRICE_FACTOR / QTY_FACTOR;
    return value.toFixed(4) + " BTC";
  } else {
    return "Encrypted";
  }
}

export const displayQty = (toParse: number): string => {
  const value = (toParse * 1.0) / QTY_FACTOR;
  return value.toFixed(2);
}

export const displayQtyFromDecryptedValue = (toParse: DecryptedValue): string => {
  if (toParse.is_decrypted) {
    const value = (toParse.decrypted_value * 1.0) / QTY_FACTOR;
    return value.toFixed(2) + " ETH";
  } else {
    return "Decrypting";
  }
}


