import { useEffect, useState } from "react";
import __wbg_init, {
  get_cipher_as_string,
  encrypt_unsigned16,
  get_public_key_from_string,
  initialize_tfhe_context,
  release_cipher,
  release_tfhe_context,
  release_public_key
} from 'sunscreen_tfhe_interop';
import { BackendCommunicator } from "./Shared/BackendCommunicator";
import { ClearanceType, MAX_PRICE, MAX_QTY, MIN_PRICE, MIN_QTY, PRICE_FACTOR, QTY_FACTOR, displayAmount, displayPrice } from "./Shared/BackendObjects";
import { SyncedObjects } from "./Shared/SyncedObjects";

type OrderFormProps = {
  user: string;
  currentMidPointPrice: number;
  isCda: boolean;
}

const OrderForm = (props: React.PropsWithChildren<OrderFormProps>) => {
  const GTC = "gtc";

  const [isBuy, setIsBuy] = useState(true);
  const [fillType, setFillType] = useState(GTC);
  const [qty, setQty] = useState('0.000');
  const [price, setPrice] = useState('');
  const [parsedQty, setParsedQty] = useState(0);
  const [parsedPrice, setParsedPrice] = useState(0);
  const [qtyError, setQtyError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [isQtyValid, setIsQtyValid] = useState(false);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [submitMessage, setSubmitMessage] = useState('');
  const [wasSubmitSuccessful, setWasSubmitSuccessful] = useState(false);

  useEffect(() => {
    initializePage();
  }, []);

  useEffect(() => {
    if (price === '') {
      setParsedPrice(Math.floor(props.currentMidPointPrice * PRICE_FACTOR));
      setIsPriceValid(true);
      setPriceError('');
    }
  }, [props.currentMidPointPrice])

  const initializePage = async () => {
    await __wbg_init();
  }

  const saveQty = (proposedQty: string) => {
    setQty(proposedQty);
    setParsedQty(0)
    try {
      if (proposedQty !== null && proposedQty.length > 0) {
        const parsedValue = parseFloat(proposedQty);
        if (parsedValue >= (MIN_QTY / QTY_FACTOR) && parsedValue <= (MAX_QTY / QTY_FACTOR)) {
          setParsedQty(Math.floor(parsedValue * QTY_FACTOR));
          setIsQtyValid(true);
          setQtyError('');
        } else {
          setIsQtyValid(false);
          setQtyError('Keep qty between ' + (MIN_QTY / QTY_FACTOR) + ' and ' + Math.floor(MAX_QTY / QTY_FACTOR));
        }
      }
    } catch {
      setIsQtyValid(false);
      setQtyError('Qty should be a positive value');
    }
  }

  const savePrice = (proposedPrice: string) => {
    setPrice(proposedPrice);
    setParsedPrice(0);
    try {
      if (proposedPrice !== null && proposedPrice.length > 0) {
        if (proposedPrice === '') {
          setParsedPrice(Math.floor(props.currentMidPointPrice * PRICE_FACTOR));
          setIsPriceValid(true);
          setPriceError('');
        } else {
          const parsedValue = parseFloat(proposedPrice);
          if (parsedValue >= (MIN_PRICE / PRICE_FACTOR) && parsedValue <= (MAX_PRICE / PRICE_FACTOR)) {
            setParsedPrice(Math.floor(parsedValue * PRICE_FACTOR));
            setIsPriceValid(true);
            setPriceError('');
          } else {
            setIsPriceValid(false);
            setPriceError('Keep price between ' + (MIN_PRICE / PRICE_FACTOR) + ' and ' + Math.floor(MAX_PRICE / PRICE_FACTOR));
          }
        }
      }
    } catch {
      setIsPriceValid(false);
      setPriceError('Price should be a positive integer');
    }
  }

  const submitOrder = async () => {
    try {
      setSubmitMessage('Submitting...');
      setWasSubmitSuccessful(true);
      const startTime = new Date().getTime();

      const context = initialize_tfhe_context();

      const publicKeyBase64 = await BackendCommunicator.getPublicKey();
      const publicKey = get_public_key_from_string(publicKeyBase64);

      const encryptedQty = encrypt_unsigned16(context, publicKey, BigInt(parsedQty));
      const encryptedQtyString = get_cipher_as_string(encryptedQty);

      const encryptedPrice = encrypt_unsigned16(context, publicKey, BigInt(props.isCda ? parsedPrice : 0));
      const encryptedPriceString = get_cipher_as_string(encryptedPrice);

      release_cipher(encryptedQty);
      release_cipher(encryptedPrice);

      await BackendCommunicator.insertNewOrder(props.user, encryptedQtyString, encryptedPriceString, !isBuy, fillType == GTC, props.isCda ? ClearanceType.CDA : ClearanceType.VM);

      release_tfhe_context(context);
      release_public_key(publicKey);

      let elapsed = new Date().getTime() - startTime;
      console.log("Setting forced update");
      SyncedObjects.forceUpdate();
      console.log("Time taken by whole execution: " + elapsed);
      setSubmitMessage('Submitted successfully');
      setWasSubmitSuccessful(true);
    } catch (e) {
      setSubmitMessage('Failed to submit order');
      setWasSubmitSuccessful(false);
      console.log("Failed to submit order");
      console.log(e);
    }
    setTimeout(() => {
      setSubmitMessage('');
    }, 2000);
  }

  return (
    <div className="border-r-2 border-solid border-color-code h-full">
      <div className="grid grid-cols-2 gap-0">
        <div className="col-span-1 border-r-2 border-solid border-color-code" onClick={() => setIsBuy(true)}>
          {isBuy ?
            <div className="buy-color font-bold text-[15px] pt-4 pb-4 text-center border-t-4 border-solid">Buy</div>
            : <div className="alt-primary-color font-bold text-[15px] pt-4 pb-4 text-center unselected-background">Buy</div>}
        </div>
        <div className="col-span-1" onClick={() => setIsBuy(false)}>
          {!isBuy ?
            <div className="sell-color font-bold text-[15px] pt-4 pb-4 text-center border-t-4 border-solid">Sell</div>
            : <div className="alt-primary-color font-bold text-[15px] pt-4 pb-4 text-center unselected-background">Sell</div>}
        </div>
      </div>
      <div className="pt-8 pl-2 pr-2 pb-5 border-b-2 border-solid border-color-code">
        {
          props.isCda &&
          <div className="mb-3">
            <div className="border-solid border-2 border-color-code pt-2 pb-2 pl-2 pr-2 rounded-lg grid grid-cols-8 gap-0 focus-within:outline-none focus-within:ring-0 focus-within:border-teal focus-within:border-4">
              <div className="primary-color text-[11px] font-bold col-span-3">LIMIT PRICE</div>
              <input onChange={(e) => savePrice(e.target.value)} value={price === '' ? props.currentMidPointPrice : price} type="text" className="order-form-input col-span-4 alt-primary-color text-[11px] border-0 p-0 m-0 standard-background focus:border-transparent" />
              <span className="alt-primary-color text-[11px] pl-2 col-span-1">BTC</span>
            </div>
            <div>
              <span className="font-semibold text-[11px] font-regularText error-color h-[5px]">{priceError}</span>
            </div>
          </div>
        }
        <div >
          <div className="border-solid border-2 border-color-code pt-2 pb-2 pl-2 pr-2 rounded-lg grid grid-cols-8 gap-0 focus-within:selected-border-color-code focus-within:border-4">
            <div className="primary-color text-[11px] font-bold col-span-3">AMOUNT</div>
            <input onChange={(e) => saveQty(e.target.value)} value={qty} type="text" className="order-form-input col-span-4 alt-primary-color text-[11px] border-0 p-0 m-0 standard-background" placeholder="0.000" />
            <span className="alt-primary-color text-[11px] pl-2 col-span-1">ETH</span>
          </div>
          <div>
            <span className="font-semibold text-[11px] font-regularText error-color h-[5px]">{qtyError}</span>
          </div>
        </div>
      </div>
      <div className="pl-2 pr-2 pt-4 pb-4 border-b-2 border-solid border-color-code">
        <div className="grid grid-cols-5 gap-0">
          <div className="alt-primary-color text-[10px] font-bold col-span-2 align-middle">TIME IN FORCE</div>
          <select name="timeinforce" id="timeinforce" className="primary-color col-span-3 text-[10px] font-bold order-form-select standard-background pt-0 pb-0 pr-1 pl-2" onChange={(e) => setFillType(e.target.value)} value={fillType}>
            <option value="gtc">GOOD TILL CANCELED</option>
            {props.isCda && <option value="ioc">FILL OR KILL</option>}
          </select>
        </div>
      </div>
      <div className="pl-2 pr-2 pt-4 pb-4">
        {
          props.isCda &&
          <>
            <div className="grid grid-cols-5 gap-0 pb-2">
              <div className="alt-primary-color text-[11px] font-bold col-span-2">SUBTOTAL</div>
              <div className="primary-color text-[11px] font-regularText col-span-3 text-right">{isQtyValid && isPriceValid ? (displayAmount(parsedQty * parsedPrice) + ' BTC') : '--'}</div>
            </div>
            <div className="grid grid-cols-5 gap-0 pb-2">
              <div className="alt-primary-color text-[11px] font-bold col-span-2">EST. FEES</div>
              <div className="primary-color text-[11px] font-regularText col-span-3 text-right">{isQtyValid && isPriceValid ? (displayPrice(0) + ' BTC (DEMO)') : '--'}</div>
            </div>
            <div className="grid grid-cols-5 gap-0 pb-2">
              <div className="primary-color text-[14px] font-bold col-span-2">TOTAL</div>
              <div className="primary-color text-[14px] font-bold col-span-3 text-right">{isQtyValid && isPriceValid ? (displayAmount(parsedPrice * parsedQty) + ' BTC') : '--'}</div>
            </div>
          </>
        }
        <div className="col-span-5">
          <button className={"pt-1 pb-1 w-full rounded-2xl font-semibold hover:opacity-80 text-[15px]" + (isBuy ? " buy-background-color " : " sell-background-color")}
            onClick={() => submitOrder()}
            disabled={(props.isCda && !isPriceValid) || !isQtyValid}>
            Encrypt & Submit Order
          </button>
          <div>
            <span className={"font-semibold text-[11px] font-regularText h-[5px]" +(wasSubmitSuccessful ? " success-color " : " error-color ")}>{submitMessage}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderForm;
