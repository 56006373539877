import axios from "axios";
import { ClearanceType, Match, Order } from "./BackendObjects";

export class BackendCommunicator {
  private static SERVICE_ENDPOINT = "https://darkpool-backend.sunscreen.tech"; //"http://127.0.0.1";

  public static async getPublicKey(): Promise<string> {
    const paramsResponse = await axios.get(
      BackendCommunicator.SERVICE_ENDPOINT + '/public_key',
      {
        headers: {
          'Content-Type': 'text/plain',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 2000
      }
    );

    return paramsResponse.data["value"];
  };

  public static async getOutstandingOrders(clearance_type: ClearanceType): Promise<[number, number]> {
    const jsonData = JSON.stringify({ clearance_type: clearance_type });
    const paramsResponse = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/get_num_outstanding_orders',
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 1000
      }
    );

    return [paramsResponse.data["outstanding_buys"], paramsResponse.data["outstanding_sells"]];
  }

  public static async getOutstandingOrderDetails(clearance_type: ClearanceType): Promise<[string[], string[]]> {
    const jsonData = JSON.stringify({ clearance_type: clearance_type });
    const paramsResponse = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/get_num_outstanding_order_details',
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 3000
      }
    );

    return [paramsResponse.data["outstanding_buys"], paramsResponse.data["outstanding_sells"]];
  }

  public static async decrypt(base: string): Promise<number> {
    const paramsResponse = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/decrypt',
      base,
      {
        headers: {
          'Content-Type': 'text/plain',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 1000
      }
    );

    return paramsResponse.data.value;
  };

  public static async getOrdersForUserId(userId: string, clearance_type: ClearanceType): Promise<number[]> {
    const jsonData = JSON.stringify({ user_id: userId, clearance_type: clearance_type });

    const response = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/get_orders_for_user_id',
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 1000
      }
    );

    return response?.data?.orders;
  }

  public static async getMatchForId(matchId: number, userId: string): Promise<Match> {
    const jsonData = JSON.stringify({ user_id: userId, id: matchId });

    const response = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/get_match_for_id',
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 1000
      }
    );

    return response?.data;
  }

  public static async getOrderForId(matchId: number, userId: string): Promise<Order> {
    const jsonData = JSON.stringify({ user_id: userId, id: matchId });

    const response = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/get_order_for_id',
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 2000
      }
    );

    return response?.data;
  }

  public static async insertNewOrder(userId: string, qty: string, price: string, is_sell: boolean, is_gtc: boolean, clearance_type: ClearanceType): Promise<void> {
    const jsonData = JSON.stringify({ user_id: userId, order_qty: qty, order_price: price, is_sell: is_sell, is_gtc: is_gtc, clearance_type: clearance_type });

    const response = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/insert_new_order',
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 1500
      }
    );

    return;
  }

  public static async cancelOrder(orderId: number, userId: string): Promise<void> {
    const jsonData = JSON.stringify({ user_id: userId, id: orderId });

    const response = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/cancel_order',
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        timeout: 1500
      }
    );

    return;
  }
}