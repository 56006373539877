import { BackendCommunicator } from './Shared/BackendCommunicator';
import { useEffect, useRef, useState } from 'react';
import {
  DecryptedOrder,
  Match,
  Order,
  OrderStatus,
  DecryptedValue,
  convertOrderStatusToString,
  convertOrderTypeToString,
  displayAmount,
  displayAmountFromDecryptedValue,
  displayPrice,
  displayPriceFromDecryptedValue,
  displayQty,
  displayQtyFromDecryptedValue
} from './Shared/BackendObjects';
import { Modal, Tooltip } from 'flowbite-react';
import { SyncedObjects } from './Shared/SyncedObjects';
import { FaInfoCircle } from 'react-icons/fa';

type PendingOrdersProps = {
  user: string;
  isCda: boolean;
}

type OrderDiff = {
  order: number,
  order_overall_delta: boolean,
}

type OrderWithTimestamp = {
  order: DecryptedOrder,
  minUpdateTime: number
}

const PendingOrders = (props: React.PropsWithChildren<PendingOrdersProps>) => {
  const previousPendingOrders = useRef<number[]>([]);
  const [currentPendingOrders, setCurrentPendingOrders] = useState<OrderDiff[]>([]);
  const [pastOrders, setPastOrders] = useState<OrderDiff[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [fillsOrderId, setFillsOrderId] = useState(0);
  const [txnsForOrder, setTxnsForOrder] = useState<Match[]>([]);
  const [showPendingOrders, setShowPendingOrders] = useState(false);
  const [wereCurrentOrdersUpdated, setWereCurrentOrdersUpdated] = useState(false);
  const [werePastOrdersUpdated, setWerePastOrdersUpdated] = useState(false);
  const [orderMap, setOrderMap] = useState<Map<Number, OrderWithTimestamp>>(new Map());
  const [decryptedValues, setDecryptedValues] = useState<Map<string, number>>(new Map());
  const [completeOrderIdList, setCompleteOrderIdList] = useState<number[]>([]);


  useEffect(() => {
    const callbackId = SyncedObjects.getPendingOrders((pendingOrders: number[]) => {
      if (pendingOrders != null && pendingOrders.length > 0) {
        let oldSet: Set<number> = new Set();
        for (const x of previousPendingOrders.current) {
          oldSet.add(x);
        }

        let currentOrders = [];
        let pastOrders = [];
        let previousPendingOrderList: number[] = [];

        let currOrdersUpdated = false;
        let pastOrdersUpdated = false

        for (const oId of pendingOrders) {
          const o = orderMap.get(oId);
          if (o) {
            if (o.order.order_status === OrderStatus.Processing || o.order.order_status === OrderStatus.Provisioning) {
              currentOrders.push({
                order: oId,
                order_overall_delta: !oldSet.has(oId),
              })
              currOrdersUpdated = currOrdersUpdated || !oldSet.has(oId);

            }
            else {
              pastOrders.push({
                order: oId,
                order_overall_delta: !oldSet.has(oId),
              });
              pastOrdersUpdated = pastOrdersUpdated || !oldSet.has(oId);
            }
            previousPendingOrderList.push(oId);
          }
          else {
            console.log("Couldnt find order " + oId);
          }
        }

        console.log(currentOrders);

        setCurrentPendingOrders(currentOrders);
        setPastOrders(pastOrders);
        setWereCurrentOrdersUpdated(currOrdersUpdated);
        setWerePastOrdersUpdated(pastOrdersUpdated);
        previousPendingOrders.current = previousPendingOrderList;
        setCompleteOrderIdList(pendingOrders);
      } else {
        setCurrentPendingOrders([]);
        setPastOrders([]);
      }
    });

    return () => {
      SyncedObjects.clearPendingCallback(callbackId);
    }
  }, []);

  useEffect(() => {
    setCurrentPendingOrders([]);
    previousPendingOrders.current = [];
  }, [props.isCda])

  useEffect(() => {
    upsertAllOrders(completeOrderIdList, SyncedObjects.getUser());
  }, [completeOrderIdList])

  const upsertAllOrders = async (orders: number[], userId: string) => {
    for (const o of completeOrderIdList) {
      await upsertOrderInMap(o, SyncedObjects.getUser());
    }
  }

  const upsertOrderInMap = async (orderId: number, userId: string) => {
    const start = Date.now();
    if (!orderMap.has(orderId) || (orderMap.get(orderId)?.minUpdateTime ?? 0) <= start) {
      try {
        const startTime = new Date();
        await BackendCommunicator.getOrderForId(orderId, userId).then((o: Order) => {
          const qtyPromise = getDecryptedResult(o.order_qty);
          const pendingQtyPromise = getDecryptedResult(o.pending_qty);
          const pricePromise = getDecryptedResult(o.order_price);
          const x: DecryptedOrder = {
            user_id: o.user_id,
            order_id: o.order_id,
            order_qty: {
              is_decrypted: qtyPromise !== undefined,
              encrypted_value: o.order_qty,
              decrypted_value: qtyPromise ?? 0,
            },
            pending_qty: {
              is_decrypted: pendingQtyPromise !== undefined,
              encrypted_value: o.pending_qty,
              decrypted_value: pendingQtyPromise ?? 0,
            },
            order_price: {
              is_decrypted: pricePromise !== undefined,
              encrypted_value: o.order_price,
              decrypted_value: pricePromise ?? 0
            },
            is_sell: o.is_sell,
            order_status: o.order_status,
            order_type: o.order_type,
            matches: o.matches,
          };

          if (o.order_status === OrderStatus.Processing || o.order_status === OrderStatus.Provisioning) {
            setOrderMap(orderMap.set(orderId, {
              order: x,
              minUpdateTime: start + 5000
            }));
          }
          else {
            setOrderMap(orderMap.set(orderId, {
              order: x,
              minUpdateTime: start + 30000
            }));
          }
          SyncedObjects.forceUpdate();
        }).catch((e) => console.log("Error getting order " + e));
      } catch {
        console.log("Failed to get order data");
      }
    }
  }

  const decryptAndSet = (encrypted: string, orderId: number) => {
    if (!decryptedValues.has(encrypted)) {
      try {
        BackendCommunicator.decrypt(encrypted).then((value) => {
          setDecryptedValues(decryptedValues.set(encrypted, value));
          let oldValue = orderMap.get(orderId);
          if (oldValue) {
            oldValue.minUpdateTime = 0;
            setOrderMap(orderMap.set(orderId, oldValue));
          }
          SyncedObjects.forceUpdate();
        }).catch((_) => console.log("Error decrypting"));
      } catch {
        console.log("Failed to decrypt");
      }
    }
  }

  const getDecryptedResult = (encrypted: string): number | undefined => {
    if (decryptedValues.has(encrypted)) {
      return decryptedValues.get(encrypted);
    }
    return undefined;
  };

  const updateFills = async (orderId: number, matchIds: number[]) => {
    const transactions = await Promise.all(matchIds.map(m => BackendCommunicator.getMatchForId(m, props.user)));
    setFillsOrderId(orderId);
    setTxnsForOrder(transactions);
    setOpenModal(true);
  }

  const cancelOrder = async (orderId: number) => {
    try {
      await BackendCommunicator.cancelOrder(orderId, props.user);
    } catch {
      console.log("Failed to cancel order");
    }
  }

  const getShortColsSpan = () => {
    return props.isCda ? "col-span-1" : "col-span-2";
  }

  const getLongColsSpan = () => {
    return props.isCda ? "col-span-2" : "col-span-3";
  }

  const getOrdersForDisplay = () => {
    return showPendingOrders ? pastOrders : currentPendingOrders;
  }

  const getFillPercentage = (order: DecryptedOrder) => {
    if (order.pending_qty.is_decrypted && order.order_qty.is_decrypted) {
      return (100 - (order.pending_qty.decrypted_value * 100 / order.order_qty.decrypted_value)).toFixed(2) + "%";
    } else {
      return "Decrypt";
    }
  }

  const getDecryptableJSXElement = (orderId: number, value: DecryptedValue, colSpan: string, renderer: (value: DecryptedValue) => string) => {
    if (value.is_decrypted) {
      return <div className={'primary-color ' + colSpan}>{renderer(value)}</div>
    } else {
      return <div className={colSpan}>
        <button
          className="clickable-color"
          onClick={() => decryptAndSet(value.encrypted_value, orderId)}>
          Decrypt
        </button>
      </div>;
    }
  }

  const getOrderJSXElement = (order: OrderDiff) => {
    const pendingOrder = orderMap.get(order.order);
    if (pendingOrder) {
      return (
        <div className={"grid grid-cols-12 pt-1 pb-1 pl-5 pr-5 text-[11px] gap-3" + (order.order_overall_delta ? ' blink-color ' : '')}>
          <div className='col-span-1 alt-primary-color'>{(props.isCda ? "CDA" : "VM") + "-" + pendingOrder.order.order_id}</div>
          <div className='col-span-1 primary-color '>{pendingOrder.order.is_sell ? "SELL" : "BUY"}</div>
          <div className='col-span-1 primary-color '>{convertOrderTypeToString(pendingOrder.order.order_type)}</div>
          {
            props.isCda && getDecryptableJSXElement(order.order, pendingOrder.order.order_price, 'col-span-2', displayPriceFromDecryptedValue)
          }
          {getDecryptableJSXElement(order.order, pendingOrder.order.order_qty, getLongColsSpan(), displayQtyFromDecryptedValue)}
          <div className={'alt-primary-color ' + getShortColsSpan()}>
            <button
              className="clickable-color"
              onClick={() => {
                if (!pendingOrder.order.pending_qty.is_decrypted) {
                  decryptAndSet(pendingOrder.order.pending_qty.encrypted_value, order.order,);
                }
                if (!pendingOrder.order.order_qty.is_decrypted) {
                  decryptAndSet(pendingOrder.order.order_qty.encrypted_value, order.order,);
                }
                updateFills(pendingOrder.order.order_id, pendingOrder.order.matches)
              }}>
              {getFillPercentage(pendingOrder.order)}
            </button>
          </div>
          {
            props.isCda &&
            <div className='col-span-2 alt-primary-color '>{displayAmountFromDecryptedValue(pendingOrder.order.order_qty, pendingOrder.order.order_price)}</div>
          }
          <div className={getShortColsSpan() + (pendingOrder.order.order_status === OrderStatus.Processing ? ' buy-color-no-back ' : ' alt-primary-color')}>
            {convertOrderStatusToString(pendingOrder.order.order_status)}
          </div>
          <div className={getShortColsSpan() + ""}>
            {
              pendingOrder.order.order_status !== OrderStatus.Canceled && pendingOrder.order.order_status !== OrderStatus.Completed &&
              <button className="clickable-color" onClick={() => cancelOrder(pendingOrder.order.order_id)}>
                Cancel
              </button>
            }
          </div>
        </div>);
    } else {
      return <></>
    }
  }

  return (
    <div>
      <div className="pt-3">
        <div className="flex flex-row">
          <button
            className={"font-bold text-[15px] mb-3 ml-3 pl-2 pr-2 inline-block " + (showPendingOrders ? "alt-primary-color" : "primary-color") + (showPendingOrders && wereCurrentOrdersUpdated ? " blink-color " : "")}
            onClick={() => setShowPendingOrders(false)}>
            Orders
          </button>
          <button
            className={"font-bold text-[15px] mb-3 ml-3 pl-2 pr-2 inline-block " + (!showPendingOrders ? "alt-primary-color" : "primary-color") + (!showPendingOrders && werePastOrdersUpdated ? " blink-color " : "")}
            onClick={() => setShowPendingOrders(true)}>
            Past Orders
          </button>
          <Tooltip style="light" content="All the orders are encrypted and the dark pool operator is unable to decrypt them until a match occurs. You may decrypt your own orders but other participants are not allowed to do so.">
            <FaInfoCircle className="text-[15px] primary-color" />,
          </Tooltip>
        </div>
        <div className='border-t-2 border-b-2 border-color-code border-solid'>
          <div className="grid grid-cols-12 pt-2 pb-2 pl-5 pr-5 text-[11px] gap-0">
            <div className='col-span-1 alt-primary-color'>ID</div>
            <div className='col-span-1 alt-primary-color'>SIDE</div>
            <div className='col-span-1 alt-primary-color'>IN FORCE</div>
            {
              props.isCda &&
              <div className='col-span-2 alt-primary-color'>PRICE</div>
            }
            <div className={'alt-primary-color ' + getLongColsSpan()}>AMOUNT</div>
            <div className={'alt-primary-color ' + getShortColsSpan()}>% FILLED</div>
            {
              props.isCda &&
              <div className='col-span-2 alt-primary-color'>TOTAL</div>
            }
            <div className={'alt-primary-color ' + getShortColsSpan()}>STATUS</div>
          </div>
        </div>
        <div className="overflow-y-auto h-[15vh]">
          {getOrdersForDisplay().map((pendingOrder) => (
            getOrderJSXElement(pendingOrder)
          ))}
        </div>
      </div>
      <Modal show={openModal} popup onClose={() => setOpenModal(false)} className="bg-opacity-50">
        <Modal.Header className='standard-background'>
          <div className='primary-color pl-5 pt-5 font-bold text-[25px] pb-5'>
            Fills for order {(props.isCda ? "CDA" : "VM") + "-" + fillsOrderId}
          </div>
        </Modal.Header>
        <Modal.Body className="standard-background primary-color">
          <div className="border-4 border-color-code border-solid p-5 rounded-2xl">
            <div className='border-t-2 border-b-2 border-color-code border-solid'>
              <div className="grid grid-cols-7 pt-2 pb-2 pl-5 pr-5 text-[11px] gap-0">
                <div className='col-span-1 alt-primary-color'>FILL ID</div>
                <div className='col-span-2 alt-primary-color'>PRICE</div>
                <div className='col-span-2 alt-primary-color'>AMOUNT</div>
                <div className='col-span-2 alt-primary-color'>TOTAL</div>
              </div>
            </div>
            {txnsForOrder.map((txn) => (
              <div className="grid grid-cols-7 pt-2 pb-2 pl-5 pr-5 text-[11px] gap-0">
                <div className='col-span-1 alt-primary-color'>{txn.match_id}</div>
                <div className='col-span-2 primary-color'>{displayPrice(txn.price)} BTC</div>
                <div className='col-span-2 primary-color'>{displayQty(txn.qty)} ETH</div>
                <div className='col-span-2 alt-primary-color'>{displayAmount(txn.qty * txn.price)} BTC</div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="standard-background">
          <button
            className="pt-1 pb-1 pl-5 pr-5 rounded-2xl font-semibold hover:opacity-80 text-[15px] buy-background-color"
            onClick={() => setOpenModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PendingOrders;
