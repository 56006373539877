import './App.css';
import Header from './components/header';
import BrowserChecker from './components/BrowserChecker';
import DarkPool from './components/DarkPool';

export function DarkPoolHome() {
  return (
    <div className="w-full">
      <Header />
      <BrowserChecker>
        <DarkPool/>
      </BrowserChecker>
    </div>
  );
}
